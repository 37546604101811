<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="login">
        <div class="cst-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="cst-logo"
          />
        </div>
        <div class="mt-1 has-text-centered">
          {{ message }}
        </div>
        <div class="field mt-5">
          <div class="control">
            <!-- <b-button type="is-primary is-fullwidth mb-3" @click="submitForm">
              Back to Login</b-button
            >
            <b-button
              type="is-primary is-light is-fullwidth mb-3"
              @click="signUp"
              >Create account</b-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserActivation",
  data() {
    return {
      message: "Activating account. Please wait...",
      url: "/auth/users/activation/",
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    uid() {
      return this.$route.params.uid;
    },
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    async activateAccount() {
      await axios
        .post(this.url, {
          uid: this.uid,
          token: this.token,
        })
        .then((response) => {
          if (response.status === 204) {
            this.message = "Account activated successfully. Redirecting...";
            setTimeout(() => {
              this.$router.push({
                name: "UserLogin",
              });
            }, 2000);
          }
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage =
            error.response.data.uid || error.response.data.token;
          this.message = "Unable to activate account.";
          this.$buefy.toast.open({
            message: this.errorMessage,
            type: "is-danger",
          });
        });
    },
  },
  mounted() {
    this.activateAccount();
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 10%;
}
</style>
